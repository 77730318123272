// Funktionen für das Content-Element: Person
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        if(isPersonContentPresent()) {
            initializeToggleBox();
        }
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function isPersonContentPresent() {
        return $('.ce-person').length > 0;
    }
    function initializeToggleBox() {
        $('.ce-person .person-toggle-button').each(function() {
           $(this).click(function() {
               $id = $(this).data('id');
               $('#toggle-' + $id + ', .toggle-' + $id).toggleClass('active');
               $('#c' + $id + ' .person-image, #c' + $id + ' .person-teaser').toggleClass('active');

               // Breite des Frames anpassen, wenn am Toggle-Button gesetzt
               $toggleFullWidth = $(this).data('toggle-fullwidth') != undefined;
               if ($toggleFullWidth) {
                   $('#c' + $id).toggleClass('fullwidth');
               }

               if ($('#toggle-' + $id).hasClass('active')) {
                   window.location.href = "#toggle-" + $id + "-open";
               } else {
                   window.location.href = "#toggle-" + $id + "-closed";
               }
           });
        });
    }
})(window.jQuery);