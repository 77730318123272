import 'mmenu-js';
import 'mburger-css';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// Plugin mmenu
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        initMenu();
        //adjustWidth();
    });

    /* RESIZE *********************************************************************************************************/
    SITE.onResize.push(function() {
        //adjustWidth();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function initMenu() {

        // Fire the plugin
        const menu = new Mmenu( "#menu-main", {
                // options
                "navbars": [

                    {
                        "position": "top",
                        "content": [
                            "<div class='close-button' id='close'></div>"
                        ]
                    }
                ],
                "slidingSubmenus": false,
                "extensions": {
                    "all" : [
                        "fx-listitems-slide"
                        //,"fx-panels-slide-up"   // Sliden des Panels nach oben
                        //,"fx-listitems-slide"   // Menuitems sliden lassen
                        //,"pagedim-black"        // Seitenhintergund ausgrauen
                        //,"multiline"          // Anzeige der Menupunkte auch mehrzeilig
                        ,"position-front"       // Anzeige im Vordergrund, Menu verschiebt nicht den Content
                        ,"position-right"       // Menu auf der rechten Seite
                        //,"shadow-page"          // Schatten am Rand des Menus zur Page
                        //,"theme-dark"          // Theme weiß
                        ,"border-none"          // Keine Linien zwischen den Menupunkten anzeigen
                        //,"fullscreen"
                    ]
                },
                navbar: { add: false }
            }, {
                // config
                classNames: {
                    selected: "current"
                },
                offCanvas: {
                    page: {
                        selector: "#page"
                    }
                },
            }
        );

        // Event registrieren, dass bei Menü-Links mit Anker das Menü schließt
        $('.mm-listitem__text').click(function() {
            if ($(this).attr("href").indexOf('#anchor') > -1) {
                menu.close();
            }
        });

        $("#close").click(function() {
            menu.close();
        });

        // Scrollbar schön machen
        const ps = new PerfectScrollbar('#panel-main', {});
    }

    /*function adjustWidth() {
        var window = document.body.offsetWidth;
        var widthpage = 1280;
        var paddingright = (window - widthpage) / 2;
        var widthmenu = 490;
        var width = widthmenu + paddingright;
        if (document.body.offsetWidth > 1279){
            $('.mm-menu').css('padding-right', paddingright + 'px');
            $('.mm-menu').css('width', width + 'px');
        }
    }*/
})(window.jQuery);