// TEMPLATE zum Kopieren für die Erweiterung neuer Funktionen (Scripts)
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        initialize();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function initialize() {
        $("#sendFeedback").click(function () {
            var url = window.location.href;
            var bodytext = url;
            var email = "Deekeling%20Arndt/AMO%20%3Cfeedback@deekeling-arndt.com%3E";
            var subject = $("#sendFeedback").attr('data-subject');
            var mail = 'mailto:' + email + '?subject=' + subject + '&body=' + escape(bodytext);
            window.location.href = mail;
        });

        $("#share").click(function () {
            var modal = document.getElementById("share-modal");
            var btn = document.getElementById("share");
            var span = document.getElementsByClassName("close")[0];
            modal.style.display = "block";
            span.onclick = function() {
                modal.style.display = "none";
            }
            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
        });

        // Bei klick auf einem Share-Verweis die Funktion socialSharePlugin ausführen
        $(".soc-icon").click(function(event) {
            event.preventDefault();
            socialNetworkShare($(this).attr("rel"));
        });
    }

    function socialNetworkShare(service) {
        var baseUrl,
            shareUrl,
            shareService,
            shareTitle = $(document).attr('title') || "Deekeling Arndt/AMO",
            shareInfo = $("meta[name=description]").attr("content") || "",
            shareHost = location.protocol + '//' + location.host + "/",
            shareImage = shareHost + "fileadmin/templates/Public/Images/Icons/256/deekeling-arndt-advisors-share-icon.png";

        switch (service) {
            case "fcb":
                baseUrl = "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=";
                shareUrl = baseUrl + encodeURIComponent(location.href) + "&p[images][0]=" + encodeURIComponent(shareImage) + "&p[title]=" + encodeURIComponent(shareTitle) + "&p[summary]=" + encodeURIComponent(shareInfo);
                shareService = "Facebook";
                break;
            case "twt":
                baseUrl = "https://twitter.com/intent/tweet?url=";
                shareUrl = baseUrl + encodeURIComponent(location.href) + "&text=" + encodeURIComponent(shareTitle) + "%0A" + encodeURIComponent(shareInfo);
                shareService = "Twitter";
                break;
            case "xng":
                baseUrl = "https://www.xing.com/social_plugins/share?url=";
                shareUrl = baseUrl + encodeURIComponent(location.href) + "&wtmc=XING;&sc_p=xing-share&p[title]=" + encodeURIComponent(shareTitle) + "&p[text]=" + encodeURIComponent(shareInfo);
                shareService = "XING";
                break;
            case "lin":
                baseUrl = "https://www.linkedin.com/shareArticle?mini=true&url=";
                shareUrl = baseUrl + encodeURIComponent(location.href) + "&title=" + encodeURIComponent(shareTitle) + "&summary=" + encodeURIComponent(shareInfo) + "&source=" + encodeURIComponent("Deekeling Arndt/AMO");
                shareService = "LinkedIn";
                break;
        }

        var NewWindow = window.open(shareUrl, shareService, "width=500,height=350,scrollbars=no");
        NewWindow.focus();
        return NewWindow;
    }
})(window.jQuery);