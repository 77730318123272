// Extern eingebundene Videos (automatische Größenanpassung der IFrames)
(function($) {
    var hasVideos = false;

    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        if ($('.video-embed-item').length > 0) {
            hasVideos = true;
        }

        resizeVideoIframes();
    });
    /* RESIZE *********************************************************************************************************/
    SITE.onResize.push(function() {
        resizeVideoIframes();
    });

    /* ORIENTATIONCHANGE **********************************************************************************************/
    SITE.onOrientationchange.push(function() {
        resizeVideoIframes();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function resizeVideoIframes() {
        if (hasVideos) {
            $('.video-embed-item').each( function(){
                $this = $(this);
                $this.height($this.width()*0.5625);
            });
        }
    }
})(window.jQuery);