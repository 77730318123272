"use strict";

// IMPORTS /////////////////////////////////////////////////////////////////////////////////////////////////////////////
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

// Globales Object anlegen, das über die Skripte im Ordner scripts mit Funktionen gefüllt werden kann
window.SITE = {
    funcs: {},     // Funktionen zur Wiederverwendung, welche über verschiedene Scripte hinweg erreichbar sein sollen
    onInit: [],    // Array mit Funktionen, die bei der Initialisierung ausgeführt werden sollen. In scripts per push()
    onResize: [],  // Array mit Funktionen, die bei Größenänderung des Fensters ausgeführt werden sollen.
    onScroll: [],  // Array mit Funktionen, die beim Scrollen auf der Seite ausgeführt werden sollen.
    onOrientationchange: []  // Funktionen, die beim Wechsel der Orientierung auf mobile Devices ausgeführt werden.
};

// CUSTOM SCRIPTS //////////////////////////////////////////////////////////////////////////////////////////////////////
require('./custom/functions.js');
require('./custom/polyfills.js');
require('./custom/images.js');
require('./custom/swiper.js');
require('./custom/navigation.js');
require('./custom/mmenu.js');
require('./custom/toggleButton.js');
require('./custom/share.js');
require('./custom/ce-person.js');
require('./custom/ce-video.js');

// CALL OF ALL SCRIPT-FUNCTIONS ////////////////////////////////////////////////////////////////////////////////////////
document.addEventListener(
    "DOMContentLoaded", () => {

    // Alle Funktionen aufrufen die beim Initialisieren der Seite ausgeführt werden sollen:
    SITE.onInit.forEach(function(func) {
        try {
            func();
        }
        catch (e) {
            SITE.funcs.logError('onInit', e);
        }
    });

    // Alle Funktionen aufrufen die beim Ändern der Fenstergröße ausgeführt werden sollen:
    window.onresize =  function (){
        SITE.onResize.forEach(function(func) {
            try {
                func();
            }
            catch (e) {
                SITE.funcs.logError('onResize', e);
            }
        });
    };

    // Alle Funktionen aufrufen die beim Scrollen ausgeführt werden sollen:
    window.onscroll =  function (){
        SITE.onScroll.forEach(function(func) {
            try {
                func();
            }
            catch (e) {
                SITE.funcs.logError('onScroll', e);
            }
        });
    };

    // Alle Funktionen aufrufen die beim Wechsel der Orientierung auf mobile Devices ausgeführt werden sollen:
    window.orientationchange =  function (){
        SITE.onOrientationchange.forEach(function(func) {
            try {
                func();
            }
            catch (e) {
                SITE.funcs.logError('onOrientationchange', e);
            }
        });
    };
});