/* Funktionen für Toggle-Button zum Anzeigen und verstecken von Elementen

    FLUID Beispiele

    1.0 Button mit Toggletext (Mehr und Weniger anzeigen)

    <button class="activity-toggle-button toggle-button btn btn-primary" data-id="toggle-{contentObject.uid}" data-alternate-text="{f:translate(key: 'show-less', extensionName: 'dia_loop')}"><f:translate key="LLL:EXT:dia_loop/Resources/Private/Language/locallang.xlf:show-more" /></button>


    2.0 zwei Button, wobei der erste beim Anzeigen der Toggle-Elemente ausgeblendet wird und der zweite innerhalb des Toggle Elements ist

    <button class="activity-toggle-button toggle-button btn btn-primary" data-id="toggle-{contentObject.uid}" data-toggle-hide="true"><f:translate key="LLL:EXT:dia_loop/Resources/Private/Language/locallang.xlf:show-more" /></button>

    <div class="toggle-area" id="toggle-{contentObject.uid}">
        <f:format.html>{field.toggleText}</f:format.html>
        <button class="activity-toggle-button toggle-button btn btn-primary" data-id="toggle-{contentObject.uid}"><f:translate key="LLL:EXT:dia_loop/Resources/Private/Language/locallang.xlf:show-less" /></button>
    </div>

 */
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        if(isTogglePresent()) {
            initializeToggleBox();
        }
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function isTogglePresent() {
        return $('.toggle-button').length > 0;
    }
    function initializeToggleBox() {
        $('.toggle-button').each(function() {
           $(this).click(function() {
               $id = $(this).data('id');

               $buttons = $(".toggle-button[data-id='" + $id + "']");
               $buttons.each(function() {
                   // Wenn es einen alternativen Text gibt, dann tauschen wir diesen im Button aus (z.B. mehr -> weniger)
                   if ($(this).data('alternate-text')) {
                       $currentText = $(this).text();
                       $(this).text($(this).data('alternate-text'));
                       $(this).data('alternate-text', $currentText);
                   }

                   // Wenn der Button ausgeblendet werden soll beim toggeln, dann tun wir dies nun
                   if ($(this).data('toggle-hide')) {
                       $(this).toggle();
                   }
               });

               // Alle Container-Elemente mit der ID oder Klasse toggeln:
               $('#' + $id + ', .' + $id).toggleClass('active');
           });
        });
    }
})(window.jQuery);