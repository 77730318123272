import Swiper from 'swiper/dist/js/swiper.js';
window.Swiper = Swiper;

// JavaScript Swiper initialisieren
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {

        /* Swiper initialisieren */
        if ($(".swiper-container").length) {

            var mySwiper = new Swiper('.swiper-container', {
                // Optional parameters
                speed: 3000,
                effect: 'fade',
                loop: 'true',
                autoplay: {
                    delay: 2500,
                },
            });
        };
    });
})(window.jQuery);


